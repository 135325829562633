<template>
	<b-card>
		<b-card-body>
			<div>
				<!-- BODY -->
				<validation-observer
					#default="{ handleSubmit }"
                	ref="refFormObserver"
				>
					<!-- Form -->
					<b-form
						@submit.prevent="handleSubmit(onSubmit)"
                    	@reset.prevent="resetForm"
					>
						<b-row>
							<b-col
								cols="12"
								class="mb-2"
							>
								<h5 class="mb-0">
									Detalles del Curso
								</h5>
								<small class="text-muted">
									Ingrese los detalles del curso.
								</small>
								<hr>
							</b-col>
							
							<b-col cols="6">
								<b-row>
									<!-- Foto img -->
									<b-col md="12">                                      
										<div id="preview_1" v-if="userData.url">
												<img class="img-fluid" :src="userData.url" width="70%" alt="image" />
										</div>

										<!-- Foto + File -->
										<b-form-group
											label="Imagen Referencial del Curso"
											label-for="photo"
										>

											<b-form-file
												id="photo"
												v-model="userData.photo"
												:state="userData.statePhoto"
												@change="onFileChange"
												accept=".jpg,.jpeg,.png"
											/>
										</b-form-group>
									</b-col>
									
									<!-- Imágen o Video -->
									<b-col md="12">
										<validation-provider
											#default="{ errors }"
											name="tipo de contenido"
											rules="required"
										>
											<b-form-group
												label-for="t-link"
												label="Tipo de contenido introductorio"
											>
												<v-select
													id="t-link"
													v-model="selectedType"
													:searchable="false"
													:state="errors.length > 0 ? false:null"
													@input="onTypeChange"
													label="title"
													placeholder="Seleccione una opción"
													:options="option"
												/>
												<small class="text-danger">{{ errors[0] }}</small>
											</b-form-group>
										</validation-provider>
									</b-col>  								
									
									<!-- Selección de tipo de contenido -->
									<b-col 
										cols="12"
										v-if="userData.type_intro == 'Imagen'"
									> 
										<hr> 
										<!-- Photo preview link -->                                    
										<div id="preview_2" v-if="userData.url_img">
											<img class="img-fluid rounded" :src="userData.url_img" width= 450 alt="image" />
										</div>

										<!-- Photo link file -->
										<validation-provider
											#default="{ errors }"
											name="imagen introductoria"
											:rules="!userData.img_intro ? 'required' : null">
												<b-form-group
													label-for="img_intro"
												>
													<b-form-file
														id="img_intro"
														v-model="userData.img_intro"
														:state="userData.stateImg"
														@change="onFileChangeImg"
														accept=".jpg,.jpeg,.png"
														placeholder="Elija un archivo o suéltelo aquí ..."
													/>
												<small class="text-danger">{{ errors[0] }}</small>
											</b-form-group>
										</validation-provider>          
									</b-col>           
								
									<b-col 
										cols="12"        
										v-else-if="userData.type_intro == 'Video'"      
									>  
										<hr>
										<!-- rules="url" -->
										<validation-provider
											#default="{ errors }"
											name="enlace del video"
											:rules="!userData.video_intro ? 'required' : null"
										>          
											<b-form-group
												label="Enlace del video"
												label-for="video_intro"
											>
												<!-- placeholder="Ingrese código de Vimeo" -->
												<b-form-input
													id="video_intro"
													type="text"
													v-model="userData.video_intro"
													:state="errors.length > 0 ? false:null"
													trim
												/>
												<small class="text-danger">{{ errors[0] }}</small>
											</b-form-group>
										</validation-provider>  
									</b-col>
									
									<b-col 
										cols="12"
										v-else
									>   
									</b-col>
								</b-row>
							</b-col>
							
							<b-col cols="6">
								<b-row> 			
									<!-- Nombre del Curso -->
									<b-col md="12">
										<validation-provider
											#default="validationContext"
											name="nombre del curso"
											rules="required"
										>
											<b-form-group
												label="Nombre del curso"
												label-for="name"
											>
												<b-form-input
													id="name"
													v-model="userData.name"
													:state="getValidationState(validationContext)"
													trim
												/>

												<b-form-invalid-feedback>
														{{ validationContext.errors[0] }}
												</b-form-invalid-feedback>
											</b-form-group>
										</validation-provider>
									</b-col>    
																																				
									<!-- Descripción del Curso -->          
									<b-col md="12">
										<validation-provider
											#default="validationContext"
											name="descripción"
											rules="required"
										>
											<b-form-group
												label="Descripción"
												label-for="description"
											>
												<b-form-textarea
													id="description"
													v-model="userData.description"
													:state="getValidationState(validationContext)"
													trim
												/>
												<b-form-invalid-feedback>
														{{ validationContext.errors[0] }}
												</b-form-invalid-feedback>
											</b-form-group>
										</validation-provider>
									</b-col>
									
									<!-- Expositor -->
									<b-col md="12">
										<validation-provider
											#default="{ errors }"
											name="expositor"
											rules="required"
										>
											<b-form-group
												label="Expositor(es)"
												label-for="speaker"
											>
												<v-select
													id="speaker"
													v-model="userData.speakerId"
													:state="errors.length > 0 ? false:null"
													multiple
													label="fullName"
													placeholder="Seleccione una o más opciones"
													:options="speakers"
													trim
												/>
												<small class="text-danger">{{ errors[0] }}</small>
											</b-form-group>
										</validation-provider>  
									</b-col>  
									
									<!-- Cliente -->
									<b-col md="12">
										<div class="repeater-form">
											<b-row
												v-for="(item, index) in userData.items"
												:id="item.id"
												:key="index"                        
												ref="row"
											>

												<!-- Nombre de Cliente -->
												<b-col sm="12" lg="7">
													<validation-provider
														#default="{ errors }"
														name="cliente"
														rules="required"
													>
														<b-form-group
															label="Cliente"
															label-for="customerId"
														>
															<v-select
																id="customerId"
																v-model="item.customerId"
																:state="errors.length > 0 ? false:null"
																:options="customers"
																label="name"
																placeholder="Seleccione una opción"
																:clearable="false"	
																trim
															/>
															<small class="text-danger">{{ errors[0] }}</small>
														</b-form-group>
													</validation-provider>
												</b-col>

												<!-- Nota Mínima -->
												<b-col sm="6" lg="3">
													<validation-provider
														#default="{ errors }"
														name="nota mínima"
														rules="between:0,20|required"
													>
														<b-form-group
															label="Nota Mínima"
															label-for="min_score"
														>
															<b-form-input
																id="min_score"
																v-model="item.min_score"
																:state="errors.length > 0 ? false:null"
																trim
															/>
															<small class="text-danger">{{ errors[0] }}</small>
														</b-form-group>
													</validation-provider>
												</b-col>

												<!-- Remove Button -->
												<b-col
													sm="6" lg="1"
													class="text-center"	
													v-if="index > 0"
												>
													<b-button
														v-ripple.400="'rgba(234, 84, 85, 0.15)'"
														variant="outline-danger"
														class="mt-2"
														@click="removeItem(index)"
													>
														<feather-icon icon="XIcon"/>
													</b-button>
												</b-col>
											</b-row>
										</div> 
													
										<b-button
											v-ripple.400="'rgba(255, 255, 255, 0.15)'"
											variant="primary"
											@click="repeateAgain"
										>
											<feather-icon
												icon="PlusIcon"
												class="mr-25"
											/>
											<span>Cliente</span>
										</b-button>
									</b-col>
										
								</b-row>
							</b-col>              
						</b-row>      							
						<br />
						
						<!-- ACTIONS -->
						<div class="d-flex mt-2">					
							<b-button
								v-ripple.400="'rgba(255, 255, 255, 0.15)'"
								variant="primary"
								class="mb-1 mb-sm-0 mr-0 mr-sm-1"
								type="submit"
							>
								Agregar
							</b-button>
						</div>
					</b-form>		
				</validation-observer>
			</div>
		</b-card-body>
	</b-card>
</template>

<script>
require('@core/assets/fonts/feather/iconfont.css')
import BCardCode from '@core/components/b-card-code'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BSidebar, BForm, BFormTextarea, BFormFile, BFormInvalidFeedback, BInputGroup, BCard,  BCardBody,
BButton, BInputGroupAppend, BRow, BCol,BFormGroup, BFormInput, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, between, url} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import axios from 'axios'
import courseStoreModule from '../courseStoreModule'
import router from '@/router'
		
export default {
	components: {
		BCard,
		BCardBody,
		BCardCode,
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BFormTextarea,
		BInputGroupAppend,
		BInputGroup,
		BFormFile,
		BFormInvalidFeedback,
		BButton,
		vSelect,
		FormWizard,
		TabContent,
		BRow,
		BCol,
		
		ToastificationContent,
		ValidationProvider,
		ValidationObserver,
		router
	},
	directives: {
		Ripple,
	},
	data() {
		return { 
			required,
			url,
			between,
			customers: [],
			speakers: [],  
			selectedType: "",      
			option: [{ title: 'Imagen', value: 1 }, { title: 'Video', value: 2}],      
		}
	},
	mounted() {    
		axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/speakers/select`)
		.then(response => {
			this.speakers = response.data;
		});
		axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/customers/select`)
		.then(response => {
			this.customers = response.data;
		});
	},
	setup(props, { emit }) {
		
		const USER_APP_STORE_MODULE_NAME = 'app-user'

		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
				if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
		})
		
		// Use toast
		const toast = useToast();
		
		const blankUserData = {
			photo: null,
			name: '',
			description: '',
			img_intro: null,
			video_intro: null,  
			speakerId: null ,
			type_intro: null,   
			items: [{
				id: 1,        
				customerId: null,
				min_score: 0,
			}],
			nextTodoId: 2,
		};
		
		const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
		const resetuserData = () => {
				userData.value = JSON.parse(JSON.stringify(blankUserData));
		};
				
		const onSubmit = () => {
			let errorE1 = 0;
			
			let customersId = userData.value.items;
			let customersArray = [];      
			customersId.map(item => {
				customersArray.push(item.customerId.id)
			});
			
			let clientArr = customersArray.map(function(item){ return item });
			let errorDuplicate = clientArr.some(function(item, idx){ 
				return clientArr.indexOf(item) != idx 
			});			
			
			if(errorDuplicate){
				errorE1 = 1;
				toast({
					component: ToastificationContent,
					props: {
						title: 'No pueden repetirse los clientes.',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					}
				});
			}
			
			if(!errorE1){
				
				let minScores = userData.value.items;
				let minScoresArray = [];      
				minScores.map(item => {
					minScoresArray.push(item.min_score)
				})
									
				let speakersId = userData.value.speakerId;
				let speakersArray = [];      
				speakersId.map(item => {
					speakersArray.push(item.id);
				})
								
				let data = {
					speakerId: speakersArray,
					customerId: customersArray,
					min_score: minScoresArray,
					name: userData.value.name,
					photo: userData.value.photo,
					statePhoto: userData.value.statePhoto,
					url: userData.value.url,
					description: userData.value.description,        
					img_intro: userData.value.img_intro,
					video_intro: userData.value.video_intro,
					type_intro: userData.value.type_intro          
				}
				
				store.dispatch('app-user/addCourse', data)
				.then( (response) => {            
											
					// emit('refetch-data') -> // Refresca data de la tabla
					resetForm()            
					toast({
						component: ToastificationContent,
						props: {
							title: response.data.message,
							icon: 'CheckIcon',
							variant: 'success'
						}                
					})                           
					router.go(-1)
				})
				.catch( (err) => {
					let message = err.response.data.message ? err.response.data.message : 'Error al crear el curso';
					toast({
						component: ToastificationContent,
						props: {
							title: message,
							icon: 'AlertTriangleIcon',
							variant: 'danger'
						}
					});
				});
			}
		};

		const onFileChange = (e) => {
			const file = e.target.files[0];
			if (file) {
				userData.value.photo = file;
				userData.value.url = URL.createObjectURL(file);
				userData.value.statePhoto = Boolean(userData.value.photo);
			} else {
				userData.value.photo = null;
				userData.value.statePhoto = null;
				userData.value.url = null;
			}
		};
				
		const onFileChangeImg = (e) => {
			const fileImg = e.target.files[0];
				if (fileImg) {
					userData.value.img_intro = fileImg;
					userData.value.url_img = URL.createObjectURL(fileImg);
					userData.value.stateImg = Boolean(userData.value.img_intro);
				} else {
					userData.value.img_intro = null;
					userData.value.stateImg = null;
					userData.value.url_img = null;
				} 
		};
		
		const onTypeChange = (e) => {
			
			if (e) { 
				const val = e.value
				if (val === 1) { 
					userData.value.type_intro = 'Imagen' 
					userData.value.video_intro = null;
				} else if (val === 2) { 
					userData.value.type_intro = 'Video'
					userData.value.img_intro = null;
					userData.value.stateImg = null;
					userData.value.url_img = null;
				}  
			}  else {
				userData.value.type_intro = null
			}
		}
		
		const repeateAgain = (e) => {
			userData.value.items.push({
				id: userData.value.nextTodoId += userData.value.nextTodoId,
				customerId: null,
				min_score: 0,
			})
		}
		
		const removeItem = (index) => {
			userData.value.items.splice(index, 1)
		}
		
		const {
			refFormObserver,
			getValidationState,
			resetForm,
		} = formValidation(resetuserData);

		return {
			userData,
			onFileChange,
			onFileChangeImg,
			onTypeChange,
			repeateAgain,
			removeItem,
			onSubmit,

			refFormObserver,
			getValidationState,
			resetForm,
		}    
	}
}
</script>

<style lang="scss">

	#preview_1, 
	#preview_2 {
		text-align: center;
		margin-bottom: 1rem;
	}  
</style>

<style lang="scss">
		@import '@core/scss/vue/libs/vue-wizard.scss';    
		@import '@core/scss/vue/libs/vue-select.scss';
</style>