import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCoursesList() {
	// Use toast
	const toast = useToast()

	const refUserListTable = ref(null)

	// Table Handlers
	let tableColumns = [
		{ key: 'name', sortable: true, label: 'Curso' },
		// { key: 'code', sortable: true, label: 'Código' },		
		{ key: 'n_usuarios', sortable: true, label: 'Usuarios Inscritos' },    
		{ key: 'n_expositores', sortable: true, label: 'Expositores Inscritos' },
		{ key: 'actions', sortable: false, label: 'Opciones' }
	]
	
	if (JSON.parse(localStorage.getItem('userData')).role == 'admin'){
		tableColumns.splice(1,0,{ key: 'code', sortable: true, label: 'Código' });
        tableColumns.splice(2,0,{ key: 'n_clientes', sortable: true, label: 'Clientes Inscritos' });
    }
	
	const perPage = ref(10)	
	const totalUsers = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('id')
	const isSortDirDesc = ref(false)

	const dataMeta = computed(() => {
		const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalUsers.value,
		}
	})

	const refetchData = () => {
		refUserListTable.value.refresh()
	}

	watch([currentPage, perPage, searchQuery], () => {
		refetchData()
	})

	const fetchCourses = (ctx, callback) => {
		store
			.dispatch('app-user/fetchCourses', {
				q: searchQuery.value,
				perPage: perPage.value,
				page: currentPage.value,
				sortBy: sortBy.value,
				sortDesc: isSortDirDesc.value,
				role: JSON.parse(localStorage.getItem('userData')).role,
            	customer: JSON.parse(localStorage.getItem('userData')).customer,
			})
			.then(response => {
				const { courses, total } = response.data
				callback(courses)
				totalUsers.value = total
			})
			.catch(() => {
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error al obtener la lista de cursos',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					},
				})
			})
	}
	
	return {
		fetchCourses,
		tableColumns,
		perPage,
		currentPage,
		totalUsers,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refUserListTable,

		refetchData,
	}
}
