var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-body',[_c('div',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Detalles del Curso ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Ingrese los detalles del curso. ")]),_c('hr')]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[(_vm.userData.url)?_c('div',{attrs:{"id":"preview_1"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.userData.url,"width":"70%","alt":"image"}})]):_vm._e(),_c('b-form-group',{attrs:{"label":"Imagen Referencial del Curso","label-for":"photo"}},[_c('b-form-file',{attrs:{"id":"photo","state":_vm.userData.statePhoto,"accept":".jpg,.jpeg,.png"},on:{"change":_vm.onFileChange},model:{value:(_vm.userData.photo),callback:function ($$v) {_vm.$set(_vm.userData, "photo", $$v)},expression:"userData.photo"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"tipo de contenido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"t-link","label":"Tipo de contenido introductorio"}},[_c('v-select',{attrs:{"id":"t-link","searchable":false,"state":errors.length > 0 ? false:null,"label":"title","placeholder":"Seleccione una opción","options":_vm.option},on:{"input":_vm.onTypeChange},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),(_vm.userData.type_intro == 'Imagen')?_c('b-col',{attrs:{"cols":"12"}},[_c('hr'),(_vm.userData.url_img)?_c('div',{attrs:{"id":"preview_2"}},[_c('img',{staticClass:"img-fluid rounded",attrs:{"src":_vm.userData.url_img,"width":"450","alt":"image"}})]):_vm._e(),_c('validation-provider',{attrs:{"name":"imagen introductoria","rules":!_vm.userData.img_intro ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"img_intro"}},[_c('b-form-file',{attrs:{"id":"img_intro","state":_vm.userData.stateImg,"accept":".jpg,.jpeg,.png","placeholder":"Elija un archivo o suéltelo aquí ..."},on:{"change":_vm.onFileChangeImg},model:{value:(_vm.userData.img_intro),callback:function ($$v) {_vm.$set(_vm.userData, "img_intro", $$v)},expression:"userData.img_intro"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):(_vm.userData.type_intro == 'Video')?_c('b-col',{attrs:{"cols":"12"}},[_c('hr'),_c('validation-provider',{attrs:{"name":"enlace del video","rules":!_vm.userData.video_intro ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Enlace del video","label-for":"video_intro"}},[_c('b-form-input',{attrs:{"id":"video_intro","type":"text","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.userData.video_intro),callback:function ($$v) {_vm.$set(_vm.userData, "video_intro", $$v)},expression:"userData.video_intro"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_c('b-col',{attrs:{"cols":"12"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"nombre del curso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Nombre del curso","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":_vm.getValidationState(validationContext),"trim":""},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Descripción","label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"description","state":_vm.getValidationState(validationContext),"trim":""},model:{value:(_vm.userData.description),callback:function ($$v) {_vm.$set(_vm.userData, "description", $$v)},expression:"userData.description"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"expositor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Expositor(es)","label-for":"speaker"}},[_c('v-select',{attrs:{"id":"speaker","state":errors.length > 0 ? false:null,"multiple":"","label":"fullName","placeholder":"Seleccione una o más opciones","options":_vm.speakers,"trim":""},model:{value:(_vm.userData.speakerId),callback:function ($$v) {_vm.$set(_vm.userData, "speakerId", $$v)},expression:"userData.speakerId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"repeater-form"},_vm._l((_vm.userData.items),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"sm":"12","lg":"7"}},[_c('validation-provider',{attrs:{"name":"cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cliente","label-for":"customerId"}},[_c('v-select',{attrs:{"id":"customerId","state":errors.length > 0 ? false:null,"options":_vm.customers,"label":"name","placeholder":"Seleccione una opción","clearable":false,"trim":""},model:{value:(item.customerId),callback:function ($$v) {_vm.$set(item, "customerId", $$v)},expression:"item.customerId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"name":"nota mínima","rules":"between:0,20|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nota Mínima","label-for":"min_score"}},[_c('b-form-input',{attrs:{"id":"min_score","state":errors.length > 0 ? false:null,"trim":""},model:{value:(item.min_score),callback:function ($$v) {_vm.$set(item, "min_score", $$v)},expression:"item.min_score"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),(index > 0)?_c('b-col',{staticClass:"text-center",attrs:{"sm":"6","lg":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1):_vm._e()],1)}),1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Cliente")])],1)],1)],1)],1)],1),_c('br'),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Agregar ")])],1)],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }