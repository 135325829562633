import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchCourses(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios                
				.get(`${process.env.VUE_APP_HOST}/api/dashboard/courses`, { params: queryParams })                                 
				.then(response => resolve(response))
				.catch(error => reject(error));
			});
		},
		fetchCourse(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
				.get(`${process.env.VUE_APP_HOST}/api/dashboard/course/${id}/edit`)
				.then(response => resolve(response))
				.catch(error => reject(error));
			})
		},
		addCourse(ctx, courseData) {
			return new Promise((resolve, reject) => {
				let formData = new FormData();
				
				formData.append("photo", courseData.photo);
				Object.entries(courseData).map(item => {
					if (item[0] !== "photo") {
						formData.append(item[0], item[1]);
					}
				});
				
				axios
				.post(`${process.env.VUE_APP_HOST}/api/dashboard/course/create`, formData, {
					headers: { 
						'Content-Type': 'multipart/form-data'
					}
				})
				.then( response => resolve(response))
				.catch(error => reject(error));
			})
		},
		updateCourse (ctx, { id, courseData }) {
			return new Promise((resolve, reject) => {	
				let formData = new FormData();						
				
				formData.append("name", courseData.name);
				formData.append("description", courseData.description);				
				formData.append("video_intro", courseData.video_intro ? courseData.video_intro : '');
				formData.append("type_intro", courseData.type_intro ? courseData.type_intro : '');
				formData.append("img_intro", courseData.img_intro);
				formData.append("photo", courseData.photo);				
				formData.append("initPhoto", courseData.initPhoto ? courseData.initPhoto : '');
				formData.append("initImg", courseData.initImg ? courseData.initImg : '');
				formData.append("changePhoto", courseData.changePhoto == 1 ? 1 : 0);
				formData.append("changeImg", courseData.changeImg == 1 ? 1 : 0);
				formData.append("deletedClient", JSON.stringify(courseData.deletedClient));
				formData.append("speakersIdNew", JSON.stringify(courseData.speakersIdNew));
				formData.append("speakersIdOld", JSON.stringify(courseData.speakersIdOld));
				formData.append("customersNew", JSON.stringify(courseData.customersNew));
				formData.append("customersOld", JSON.stringify(courseData.customersOld));
				
				axios
				.put(`${process.env.VUE_APP_HOST}/api/dashboard/course/${id}/update`, formData, {
					headers: { 
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => resolve(response))
				.catch(error => reject(error));
			});
		},
		deleteCourse (ctx, { id }){
			return new Promise((resolve, reject) => {
				axios
				.get(`${process.env.VUE_APP_HOST}/api/dashboard/course/${id}/delete`)
				.then(response => resolve(response))
				.catch(error => reject(error));
			});
		},    
		
        fetchCoursesQualifications(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/courses/qualifications`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },  
	},
}
